import './App.css';
import LinkedInLogo from './icon-linkedin.png'
import GitHubLogo from './icon-github.png'

const LinkIcon = (props) => {
    return (
        <a href={props.url}>
            <img src={props.logo} alt={props.alt}/>
        </a>
    )
}


function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Jeffrey Lu</h1>
                <p>Software & Game Developer</p>
                <div className="links">
                    <LinkIcon url={"https://www.linkedin.com/in/ljeff/"} logo={LinkedInLogo} alt={"LinkedIn Logo"}/>
                    <LinkIcon url={"https://www.github.com/lujeff"} logo={GitHubLogo} alt={"GitHub Logo"}/>
                </div>
            </header>
        </div>
    );
}

export default App;
